import { Box, Typography } from '@mui/material'
import * as XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'
import { Button, Tooltip, Chip } from '@mui/material'

import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'

//icons
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import CreateIcon from '@mui/icons-material/Create'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import SendIcon from '@mui/icons-material/Send'
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend'
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt'
import WarningIcon from '@mui/icons-material/Warning'
import {
  COMPANY_LEVELS,
  ENV_URLS,
  COMPANY_STATUS,
  ORDER_STATUS_ENUM,
  DATE_FORMATE,
  TOAST_MESSAGES,
  ERROR_STATUS_CODES,
} from './Constants'
import dayjs from 'dayjs'

// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< -------- CUSTOM HELPER FUNCTIONS --------- >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

// <<<<<<<< -------- TOKEN STORAGE & RETRIEVAL FUNCTIONS --------- >>>>>>>>>>

// SAVE TOKEN
export const saveToken = (token) => {
  localStorage.setItem(ENV_URLS.TOKEN_IDENTIFIER, token)
}

// GET TOKEN
export const getToken = () => localStorage.getItem(ENV_URLS.TOKEN_IDENTIFIER)

// DECODE TOKEN
export const decodeToken = (token) => (token && jwt_decode(token)) || null

export const getCompanyId = () => {
  const token = getToken()
  const decodedToken = decodeToken(token)
  let companyId = ''
  if (decodedToken) {
    companyId = searchParams.get('company_id')
    if (!companyId) {
      companyId = decodedToken?.company_id
    }
  }
  return companyId
}

// <<<<<<<< -------- AUTHENTICATION HELPERS --------- >>>>>>>>>>

// CommonLogin.JSX Helpers ------ START
export const redirectionFunction = (decodedToken, navigateTo, toast) => {
  switch (decodedToken.decoded_companylevel) {
    case 1:
      return masternavigation(decodedToken, navigateTo)
    case 2:
      return serviceprovidernavigation(decodedToken, navigateTo, toast)
    case 3:
      return resellernavigation(decodedToken, navigateTo, toast)
    case 4:
      return customernavigation(decodedToken, navigateTo, toast)
    case 5:
      navigateTo('/salesrep', { replace: true })
      break
    default:
      navigateTo('/login')
  }
}

const masternavigation = (decodedToken, navigateTo) => {
  if (decodedToken.decoded_companystatus == COMPANY_STATUS.Active) {
    navigateTo('/master', { replace: true })
  }
}

const serviceprovidernavigation = (decodedToken, navigateTo, toast) => {
  if (decodedToken.decoded_companystatus == COMPANY_STATUS.Active) {
    navigateTo('/serviceprovider', { replace: true })
  } else if (decodedToken.decoded_companystatus == COMPANY_STATUS.Draft) {
    sessionStorage.setItem('lastname', draftemail)
    localStorage.setItem('companyid', decodedToken.decoded_companyid)
    navigateTo('/signup-stepper')
  } else if (decodedToken.decoded_companystatus == COMPANY_STATUS.Rejected) {
    localStorage.setItem('companyid', decodedToken.decoded_companyid)
    navigateTo('/signup-stepper')
  } else if (
    decodedToken.decoded_companystatus == COMPANY_STATUS.ApprovalPending
  ) {
    toast.showToast('Please wait for the Admin to Approve', 'info')
  }
}

const resellernavigation = (decodedToken, navigateTo, toast) => {
  if (decodedToken.decoded_companystatus == COMPANY_STATUS.Active) {
    navigateTo('/reseller', { replace: true })
  } else if (decodedToken.decoded_companystatus == COMPANY_STATUS.Draft) {
    localStorage.setItem('companyid', decodedToken.decoded_companyid)
    navigateTo('/signup-stepper')
  } else if (decodedToken.decoded_companystatus == COMPANY_STATUS.Rejected) {
    localStorage.setItem('companyid', decodedToken.decoded_companyid)
    navigateTo('/signup-stepper', { replace: true })
  } else if (
    decodedToken.decoded_companystatus == COMPANY_STATUS.ApprovalPending
  ) {
    toast.showToast('Please wait for the Admin to Approve', 'info')
  }
}

const customernavigation = (decodedToken, navigateTo, toast) => {
  if (decodedToken.decoded_companystatus == COMPANY_STATUS.Active) {
    navigateTo('/customer', { replace: true })
  } else if (decodedToken.decoded_companystatus == COMPANY_STATUS.Draft) {
    localStorage.setItem('companyid', decodedToken.decoded_companyid)
    navigateTo('/signup-stepper', { replace: true })
  } else if (decodedToken.decoded_companystatus == COMPANY_STATUS.Rejected) {
    localStorage.setItem('companyid', decodedToken.decoded_companyid)
    navigateTo('/signup-stepper', { replace: true })
  } else if (
    decodedToken.decoded_companystatus == COMPANY_STATUS.ApprovalPending
  ) {
    toast.showToast('Please wait for the Admin to Approve', 'info')
  }
}

// CommonLogin.JSX Helpers ------ END

/**
 * Checks if the current user has the required permission.
 *
 * @param {Array<string>} arrayPerm - An array of required permissions.
 * @param {Object} currentDetails - An object containing the user's company level.
 * @param {string} [level] - Optional company level to check against.
 * @return {boolean} True if the user has the required permission, false otherwise.
 */
export function checkPermission(arrayPerm, currentDetails, level) {
  const permissions = JSON.parse(localStorage.getItem('permissionList'))
  if (!permissions || !arrayPerm || !currentDetails) return false

  const companyLevel = getCompanyLevelName(currentDetails.companyLevel)
  const permissionBool = permissions.some((e) => {
    const [_, permLevel] = e.split('.')
    return (
      (level ? level === permLevel : companyLevel === permLevel) &&
      arrayPerm.includes(e)
    )
  })

  return permissionBool
}

export const isPhoneNumber = (source) => /^(\d{3}){2}\d{4}$/.test(source)
// const toNumericalText = source => replace(source, /[^\d]/g, '');

const phoneNumberPattern = /(\d{3})(\d{3})(\d{4})/
export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(phoneNumberPattern)
  if (match) {
    return match[1] + ' ' + match[2] + ' ' + match[3]
  }
  return null
}

// CONVERSIONS
export function convertBase64ToHtml(base64String) {
  const decodedString = atob(base64String)
  const bodyContentMatch = decodedString.match(/<body[^>]*>([\s\S]*?)<\/body>/i)
  let content = bodyContentMatch ? bodyContentMatch[1] : ''
  // Replace all 'display:none' with 'display:block'
  // content = content.replace(/display:none/g, 'display:block');

  // Render the decoded string as HTML
  return <div dangerouslySetInnerHTML={{ __html: content }} />
}

//create a conversion function to convert  File to base64String
export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
    fileReader.readAsDataURL(file)
  })
}

export const isResponseSuccess = (response) => {
  const status = response?.status || response.statusCode
  return response && (status === 200 || status === 201 || status === 204 || status === 202)
}

export const normalizeApiResponse = (error, maxLength = 100) => {
  if (!error) return {}

  const message =
    error?.response?.data?.message ||
    error?.response?.data?.Message ||
    error.message ||
    TOAST_MESSAGES.SomethingWentWrong

  return error.message
    ? {
      statusCode: error.statusCode || ERROR_STATUS_CODES.CORS,
      message:
        message.length > maxLength
          ? `${message.substring(0, maxLength)}...`
          : message,
      isError: error.isError || true,
      responseException: error.responseException,
      result: error.result,
    }
    : {
      statusCode:
        error.response.data.statusCode || error.response.data.StatusCode,
      message:
        message.length > maxLength
          ? `${message.substring(0, maxLength)}...`
          : message,
      isError: error.response.data.isError || error.response.data.IsError,
      responseException:
        error.response.data.responseException ||
        error.response.data.ResponseException,
      result: error.response.data.result || error.response.data.Result,
    }
}

export const executeWithTryCatch = async (asyncFunc, errorHandler, params) => {
  try {
    return await asyncFunc(params)
  } catch (error) {
    if (errorHandler) {
      errorHandler(error, params)
    } else {
      console.error('An error occurred:', error)
    }
  }
}

/**
 * Routes the user to a specific level based on the company level and company ID.
 *
 * @param {Object} options - The options for routing.
 * @param {string} options.companyLevel - The level of the company.
 * @param {string} options.companyId - The ID of the company.
 * @return {void}
 */
export const RoutingLevelFunction = ({ companyLevel, companyId }) => {
  const tokenCompanyLevel = decodeToken(getconfig()[1]).company_level
  const isValidLevel =
    Object.values(COMPANY_LEVELS).includes(companyLevel) &&
    Object.values(COMPANY_LEVELS).includes(tokenCompanyLevel)

  if (isValidLevel) {
    const targetLevel = Object.keys(COMPANY_LEVELS).find(
      (key) => COMPANY_LEVELS[key] === companyLevel
    )
    const targetUrl =
      companyLevel === tokenCompanyLevel
        ? `${targetLevel}`
        : `${targetLevel}?company_id=${companyId}`

    window.location.href = targetUrl
  }
}

export const getconfig = () => {
  const token = getToken()
  const config = { headers: { Authorization: `Bearer ${token}` } }
  if (token) {
    const data = [config, token]
    return data
  } else {
    return null
  }
}

/**
 * Retrieves the company ID from the URL parameters or from the decoded token in the configuration.
 * If the company ID is not found, navigates to the login page.
 *
 * @param {function} navigateTo - The function to navigate to a specific page.
 * @return {string|undefined} The company ID, or undefined if not found.
 */
export const gettingCompanyId = (navigateTo) => {
  const urlParams = new URLSearchParams(window.location.search)
  const config = getconfig()
  const companyId =
    urlParams.get('company_id') ||
    (config?.[1] ? decodeToken(config[1]).company_id : undefined)
  if (!companyId) {
    navigateTo('/login')
  }
  return companyId
}

/**
 * Retrieves the company level based on the provided company ID and route.
 *
 * @param {string} companyId - The ID of the company.
 * @param {string} route - The route to be used if companyId is provided.
 * @return {string} The company level, or an empty string if not found.
 */
export const getCompanyLevel = (companyId, route) => {
  const token = getToken()
  const decodedToken = decodeToken(token)

  // Check if route exists, if not, use the company level from the decoded token and return it
  return companyId
    ? route
    : Object.keys(COMPANY_LEVELS).find(
        (key) => COMPANY_LEVELS[key] === decodedToken.company_level
      ) || ''
}

/**
 * Retrieves the company level name based on the provided company ID.
 *
 * @param {number} companyId - The ID of the company.
 * @return {string} The company level name, or an empty string if not found.
 */
export const getCompanyLevelName = (companyId) => {
  // Check if companyId exists, if true, return the corresponding company level name, otherwise return an empty string
  return companyId
    ? Object.keys(COMPANY_LEVELS).find(
        (key) => COMPANY_LEVELS[key] === companyId
      )
    : ''
}

export const getCompanyName = (companyId) => {
  if (companyId) {
    switch (companyId) {
      case 1:
        return 'Master'
      case 2:
        return 'Service Provider'
      case 3:
        return 'Reseller'
      case 4:
        return 'Customer'
      case 5:
        return 'Sales Rep'
      default:
        return ''
    }
  }
}

export const allowProcessingStatus = (status) => {
  if (status == 0 || status == 3) {
    return true
  }
  return false
}
export const getProcessingStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <Tooltip title="RECEIVED">
          <Chip
            size="small"
            variant="outlined"
            icon={<CheckCircleIcon sx={{ '&&': { color: '#ffc900' } }} />}
            label="RECEIVED"
          />
        </Tooltip>
      )
    case 2:
      return (
        <Tooltip title="PROCESSING">
          <Chip
            size="small"
            variant="outlined"
            icon={
              <DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />
            }
            label="PROCESSING"
          />
        </Tooltip>
      )
    case 3:
      return (
        <Tooltip title="COMPLETE">
          <Chip
            size="small"
            variant="outlined"
            icon={<CheckCircleIcon sx={{ '&&': { color: '#6def50' } }} />}
            label="COMPLETE"
          />
        </Tooltip>
      )
    case 4:
      return (
        <Tooltip title="PARTIAL">
          <Chip
            size="small"
            variant="outlined"
            icon={
              <DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />
            }
            label="PARTIAL"
          />
        </Tooltip>
      )
    case 5:
      return (
        <Tooltip title="FAILED">
          <Chip
            size="small"
            variant="outlined"
            icon={<DoNotDisturbAltIcon sx={{ '&&': { color: '#bd051d' } }} />}
            label="FAILED"
          />
        </Tooltip>
      )
  }
}

export const getAvailableIconStatus = () => {
  return (
    <Tooltip title="Available">
      <Chip
        size="small"
        variant="outlined"
        icon={<CheckCircleIcon sx={{ '&&': { color: '#6def50' } }} />}
        label="Available"
      />
    </Tooltip>
  )
}

export const getMaxUCOrderIconStatus = (status) => {
  switch (status) {
    case 0:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />}
          label="Draft"
        />
      )
    case ORDER_STATUS_ENUM.Cancelled:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DoNotDisturbAltIcon sx={{ '&&': { color: '#bd051d' } }} />}
          label="Cancelled"
        />
      )
    case ORDER_STATUS_ENUM.PendingCancellation:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DoNotDisturbAltIcon sx={{ '&&': { color: '#bd051d' } }} />}
          label="Pending Cancellation"
        />
      )
    case ORDER_STATUS_ENUM.Draft:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />}
          label="Draft"
        />
      )
    case ORDER_STATUS_ENUM.Pending:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />}
          label="Pending"
        />
      )

    case ORDER_STATUS_ENUM.InProgress:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />}
          label="In Progress"
        />
      )
    case ORDER_STATUS_ENUM.Completed:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<CheckCircleIcon sx={{ '&&': { color: '#6def50' } }} />}
          label="Completed"
        />
      )
    case ORDER_STATUS_ENUM.CancellationApproved:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<CheckCircleIcon sx={{ '&&': { color: '#6def50' } }} />}
          label="Cancellation Approved"
        />
      )
  }
}

export const getPortingIconStatus = (status) => {
  switch (status) {
    case 1:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DoNotDisturbAltIcon sx={{ '&&': { color: '#bd051d' } }} />}
          label="Cancelled"
        />
      )
    case 2:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />}
          label="Draft"
        />
      )
    case 4:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />}
          label="Pending"
        />
      )
    case 8:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />}
          label="Need Info"
        />
      )
    case 16:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />}
          label="Porting"
        />
      )
    case 32:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<CheckCircleIcon sx={{ '&&': { color: '#6def50' } }} />}
          label="Ported"
        />
      )
  }
}

export const getNumberIconStatus = (status) => {
  switch (status) {
    case 0:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DoNotDisturbAltIcon sx={{ '&&': { color: '#bd051d' } }} />}
          label="Debug"
        />
      )
    case 1:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<CheckCircleIcon sx={{ '&&': { color: '#6def50' } }} />}
          label="Active"
        />
      )
    case 2:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DoNotDisturbAltIcon sx={{ '&&': { color: '#bd051d' } }} />}
          label="Disabled"
        />
      )
    case 4:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />}
          label="Holding"
        />
      )
    case 8:
      return (
        <Chip
          size="small"
          variant="outlined"
          icon={<DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />}
          label="Port pending"
        />
      )
  }
}

export const getNumberAssignmentStatusIcon = (status, number) => {
  if (number) {
    switch (status) {
      case 0:
        return (
          <Tooltip title="Unassigned">
            <Chip
              size="small"
              variant="outlined"
              style={{ width: '120px' }}
              icon={<WarningIcon />}
              label="unassigned"
            />
          </Tooltip>
        )
      case 1:
        return (
          <Tooltip title="Provisioning">
            <Chip
              size="small"
              variant="outlined"
              style={{ width: '120px' }}
              icon={
                <DownloadingOutlinedIcon sx={{ '&&': { color: '#ffc900' } }} />
              }
              label={number}
            />
          </Tooltip>
        )
      case 2:
        return (
          <Tooltip title="Assigned">
            <Chip
              size="small"
              variant="outlined"
              style={{ width: '120px' }}
              icon={<CheckCircleIcon sx={{ '&&': { color: '#6def50' } }} />}
              label={number}
            />
          </Tooltip>
        )
      case 3:
        return (
          <Tooltip title="Removed">
            <Chip
              size="small"
              variant="outlined"
              style={{ width: '120px' }}
              icon={<DoNotDisturbAltIcon sx={{ '&&': { color: '#bd051d' } }} />}
              label={number}
            />
          </Tooltip>
        )
    }
  } else {
    return (
      <Tooltip title="Unassigned">
        <Chip
          size="small"
          variant="outlined"
          style={{ width: '120px' }}
          icon={<WarningIcon />}
          label="unassigned"
        />
      </Tooltip>
    )
  }
}

export const getOrderTypeName = (orderType) => {
  switch (orderType) {
    case 0:
      return 'None'
    case 1:
      return 'Initial Order '
    case 2:
      return 'Feature Order'
    case 3:
      return 'Remove Feature Order'
  }
}

export const getAccountType = (type) => {
  switch (type) {
    case 0:
      return 'None'
    case 1:
      return 'User'
    case 2:
      return 'Service'
    case 3:
      return 'Failed'
  }
}
export const getNumberAssignmentStatus = (type) => {
  switch (type) {
    case 0:
      return 'None'
    case 1:
      return 'Provisioning'
    case 2:
      return 'Done'
    case 3:
      return 'Failed'
  }
}
export const getCategoryName = (type) => {
  switch (type) {
    case 0:
      return 'Uncategorized'
    case 1:
      return 'Primary'
    case 2:
      return 'Secondary'
  }
}

// ---------------- ARRAY OR OBJECT HELPER FUNCTIONS -----------
export const cleanArray = (arrayOfObjects) => {
  const cleanedArray = arrayOfObjects
    .map((obj) =>
      Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
          acc[key] = value
        }
        return acc
      }, {})
    )
    .filter((obj) => Object.keys(obj).length > 0)
  return cleanedArray
}

/**
 * Creates an array of grouped sequences from the input array.
 *
 * The function groups the input array into sequences based on the difference between the 'Did' property of each object and its index in the array.
 *
 * @param {Array} arr - The input array of objects with a 'Did' property.
 * @return {Array} An array of grouped sequences.
 */
export const createGroupSequenceArray = (arr) => {
  const result = []
  let temp = []
  let difference

  for (let i = 0; i < arr.length; i++) {
    const currentDiff = arr[i]?.Did - i
    if (currentDiff !== difference) {
      if (difference !== undefined) {
        result.push(temp)
      }
      temp = [arr[i]]
      difference = currentDiff
    } else {
      temp.push(arr[i])
    }
  }

  if (temp.length) {
    result.push(temp)
  }
  return result
}

//remove duplicates
export function removeDuplicates(data) {
  return data != null && data?.length > 0
    ? data?.filter(
        (obj, index, self) => index === self.findIndex((t) => t.id === obj.id)
      )
    : []
}

// ----------- DATE &  CUSTOM FORMATE -----------

//early termination fee calculation
export const calculateETF = (
  contractStartDate,
  cancellationDate,
  monthlyServiceFee
) => {
  const endDate = new Date(
    contractStartDate.getFullYear() + 1,
    contractStartDate.getMonth(),
    contractStartDate.getDate()
  ) // 1 year contract
  const halfOfMonthlyServiceFee = monthlyServiceFee / 2

  // Calculate the difference in months
  const remainingMonths =
    (endDate.getFullYear() - cancellationDate.getFullYear()) * 12 +
    (endDate.getMonth() - cancellationDate.getMonth())

  // Ensure remainingMonths is not negative
  const validRemainingMonths = Math.max(remainingMonths, 0)

  // Calculate ETF
  const etf = validRemainingMonths * halfOfMonthlyServiceFee

  return etf
}

export const formatPrice = (price) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formatter.format(price)
}

export const formatDate = (date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const year = date.getFullYear().toString().slice(-2)
  const month = months[date.getMonth()]
  const day = date.getDate()
  const formattedDate = `${day} ${month} ${year}`

  return formattedDate
}

export const formatDateString = (dateString) => {
  return dayjs(dateString).format(DATE_FORMATE)
}

export const formatDateAsFullMonth = (date) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const year = date.getFullYear().toString()
  const month = months[date.getMonth()]
  const day = date.getDate()
  const formattedDate = `${year}-${month}-${day}`

  return formattedDate
}

export const customDateSort = (a, b) => {
  const parseDate = (dateString) => {
    return dayjs(dateString, 'DD MMM YY')
  }

  const dateA = parseDate(a)
  const dateB = parseDate(b)

  if (dateA.isBefore(dateB)) return -1
  if (dateA.isAfter(dateB)) return 1
  return 0
}

export const hexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace('#', '')
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 12) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}
export function stringAvatar(name) {
  if (name) {
    const splitVal = name.split(' ')
    if (splitVal.length > 1) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      }
    } else {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}`,
      }
    }
  }
}
export function getStringAvatarWithoutColor(name) {
  if (name) {
    const splitVal = name.split(' ')
    if (splitVal.length > 1) {
      return {
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      }
    } else {
      return {
        children: `${name.split(' ')[0][0]}`,
      }
    }
  }
}

// ----------- FORMS FUNCTIONS -----------

export const requiredLabel = (label, isRequired = true) => {
  return (
    <Box sx={{ display: 'flex', gap: '5px', my: 1 }}>
      <Typography variant="label">
        {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
      </Typography>
    </Box>
  )
}

export const errorMessage = (message, variant = 'caption') => {
  return (
    <Typography color="red" variant={variant}>
      {message}
    </Typography>
  )
}

export const hostStringMapper = (hosts) => {
  const hostString = hosts
    .map((host) => `${host.address}${host.netMask}`)
    .join(',')
  return hostString
}

export const hostStringSplitter = (host) => {
  if (host.includes(',')) {
    const hostString = host.split(',').map((item) => {
      const [address, netMask] = item.split('/')
      return { address, netMask: netMask ? `/${netMask}` : '' }
    })
    return hostString
  }
  const [address, netMask] = host.split('/')
  return [{ address, netMask: netMask ? `/${netMask}` : '' }]
}

export const getTouchedObj = (errors) => {
  const touched = {}
  Object.keys(errors).map((key) => {
    if (Array.isArray(errors[key])) {
      errors[key].map((val, index) => {
        if (index == 0) touched[key] = []
        touched[key].push(getTouchedObj(val))
      })
    } else {
      touched[key] = true
    }
  })

  return touched
}

// -------- EXCEL -----------

export const ExportExcel = ({ excelData, fileName, buttonName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(excelData)

    // Function to calculate the maximum width of the content in each column
    const fitToColumn = (data) => {
      // Get the keys of the first object (header names)
      const headers = Object.keys(data[0])
      const columnWidths = headers.map((header) => {
        // Calculate the maximum width of the column content
        const maxWidth = Math.max(
          ...data.map((row) =>
            row[header] ? row[header].toString().length : 0
          ),
          header.length
        )
        return { wch: maxWidth }
      })
      return columnWidths
    }

    // Apply styles to the worksheet
    const range = XLSX.utils.decode_range(ws['!ref'])
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R }
        const cell_ref = XLSX.utils.encode_cell(cell_address)
        if (!ws[cell_ref]) continue // If cell doesn't exist, skip it
        // If the cell exists and it is the first row (header), set the bold style
        if (ws[cell_ref] && R === 0) {
          ws[cell_ref].s = {
            font: {
              bold: true,
            },
          }
        }
        ws[cell_ref].s = {
          alignment: {
            horizontal: 'left',
          },
        }
      }
    }

    // Set the widths for each column including headers
    ws['!cols'] = fitToColumn(excelData)

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], {
      type: fileType,
    })
    FileSaver.saveAs(data, fileName + '.xlsx')
  }

  return (
    <Tooltip title="Export Data">
      <Button
        onClick={exportToExcel}
        type="button"
        sx={{ p: '20px', mr: '10px' }}
        aria-label="Export"
      >
        Export Data
        <FileDownloadIcon />
      </Button>
    </Tooltip>
  )
}

// ----------- NOTIFICATIONS -----------

export const getContactTypeName = (value) => {
  if (value) {
    switch (value) {
      case 1:
        return 'Primary'
      case 2:
        return 'Billing'
      case 3:
        return 'Technical'
      default:
        return ''
    }
  }
}

export const getNotificationName = (value) => {
  if (value) {
    switch (value) {
      case 0:
        return 'Unknown'
      case 1:
        return (
          <>
            Draft{' '}
            <CreateIcon
              sx={{
                '&&': { color: '#ffc900', fontSize: '16px', m: '-3px 5px' },
              }}
            />
          </>
        )
      case 2:
        return (
          <>
            Scheduled{' '}
            <ScheduleSendIcon
              sx={{
                '&&': { color: '#ffc900', fontSize: '16px', m: '-3px 5px' },
              }}
            />
          </>
        )
      case 3:
        return (
          <>
            Sent{' '}
            <SendIcon
              sx={{
                '&&': { color: '#6def50', fontSize: '16px', m: '-3px 5px' },
              }}
            />
          </>
        )
      case 4:
        return (
          <>
            Canceled{' '}
            <CancelScheduleSendIcon
              sx={{
                '&&': { color: '#bd051d', fontSize: '16px', m: '-3px 5px' },
              }}
            />
          </>
        )
      default:
        return ''
    }
  }
}

// --------- TABLE HELPERS -------------

export function descendingComparator(a, b, orderBy) {
  if (orderBy) {
    if (typeof getPropByString(a, orderBy) == 'number') {
      if (getPropByString(b, orderBy) < getPropByString(a, orderBy)) {
        return -1
      }
      if (getPropByString(b, orderBy) > getPropByString(a, orderBy)) {
        return 1
      }
      return 0
    } else {
      if (getPropByString(a, orderBy) && getPropByString(b, orderBy)) {
        return getPropByString(a, orderBy).localeCompare(
          getPropByString(b, orderBy),
          undefined,
          { numeric: true }
        )
      } else if (getPropByString(a, orderBy)) {
        return 1
      } else if (getPropByString(b, orderBy)) {
        return -1
      } else {
        return 0
      }
    }
  }
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getPropByString(obj, propString) {
  if (checkNested(obj, propString)) {
    if (!propString) return obj

    var prop,
      props = propString.split('.')

    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
      prop = props[i]

      var candidate = obj[prop]
      if (candidate !== undefined) {
        obj = candidate
      } else {
        break
      }
    }
    return obj[props[i]]
  }
}

function checkNested(obj, propString) {
  var args = propString.split('.')
  for (var i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false
    }
    obj = obj[args[i]]
  }
  return true
}

export function isValidService(service) {
  const keys = Object.keys(SERVICES)
  for (const key of keys) if (String(SERVICES[key]) === service) return true
  return false
}

export const SanitizeSpecialCharacters = (value) => {
  return value.replace(/[^a-zA-Z0-9\s]/g, '')
}
export const sanitizeExceptAlphabets = (str) => {
  return str.replace(/[^a-zA-Z\s]/g, '');
}